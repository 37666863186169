import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { dateHydratingSchema } from "@models/types/Dates";
import { ShowroomSeasonList } from "@models/types/enums";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export namespace GetOrderDeadline {
  export const outputSchema = z.object({
    brandId: brandSchema.shape.id,
    deadline: dateHydratingSchema,
    season: z.enum(ShowroomSeasonList),
    year: z.number(),
  });

  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    brandId: string | undefined;
    season: string;
    year: number;
  }

  export function path({ brandId, season, year }: HookParams) {
    return `brands/${brandId}/order-deadlines/${season}/${year}`;
  }

  export const getQueryKeys = (p: HookParams) => getAPIQueryKey(path(p));

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data));
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getQueryKeys(params),
      queryFn: () => call(params),
    });
  }
}
