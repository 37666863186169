import React from "react";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { FaRegHourglassHalf } from "react-icons/fa6";
import { HiOutlinePencil } from "react-icons/hi";
import { HiPlusCircle } from "react-icons/hi2";

import Button from "@components/data-entry/Button";
import Modal, { useModal } from "@components/feedback/Modal";
import { ACL_ADMINS } from "@shared/components/access-control/helpers";

import OrderDeadlineForm from "./orderDeadlineForm";

interface OrderDeadlineDisplayProps {
  deadline: Date | null | undefined;
  brandId: string;
  season: string;
  year: number;
}

function OrderDeadlineDisplay({
  deadline,
  brandId,
  season,
  year,
}: OrderDeadlineDisplayProps) {
  const modalState = useModal();
  const { t } = useTranslation();

  return (
    <div>
      {!deadline ? (
        <>
          {/* the "Add order deadline" button is displayed */}
          <div className="my-2 flex">
            <Button
              aclRoles={ACL_ADMINS}
              theme="TERTIARY"
              onClick={modalState.open}
              aria-label={t("Shared.order-deadline.add-order-deadline")}
            >
              <HiPlusCircle />
              {t("Shared.order-deadline.add-order-deadline")}
            </Button>

            <Modal
              title={t("Shared.order-deadline.add-order-deadline")}
              state={modalState}
              centeredTitle
              padding
              aria-label={t("Shared.order-deadline.add-order-deadline")}
            >
              <div className="modal-content p-1">
                <OrderDeadlineForm
                  deadline={deadline}
                  brandId={brandId}
                  season={season}
                  year={year}
                  closeModal={modalState.close}
                />
              </div>
            </Modal>
          </div>
        </>
      ) : (
        <div className="my-2 flex">
          {/* The order deadline date is displayed */}
          <div className="flex items-center">
            <Button
              aclRoles={ACL_ADMINS}
              theme="HOVER_COLOR"
              type="button"
              onClick={modalState.open}
              aria-label={format(deadline, "MMM d, yyyy")}
            >
              <div className="text-primaryDarkGrey flex items-center space-x-1">
                <FaRegHourglassHalf className="my-auto w-2.5 mr-1" />
                <p>{t("Shared.order-deadline.order-deadline")}</p>
              </div>
              <p className="flex items-center ">
                {format(deadline, "MMM d, yyyy")}
              </p>
              <HiOutlinePencil />
              {/* {t("Shared.order-deadline.edit-deadline")} */}
            </Button>
          </div>
          <Modal
            title={t("Shared.order-deadline.edit-order-deadline")}
            state={modalState}
            centeredTitle
            padding
            aria-label={format(deadline, "MMM d, yyyy")}
          >
            <div className="modal-content p-1">
              <OrderDeadlineForm
                deadline={deadline}
                brandId={brandId}
                season={season}
                year={year}
                closeModal={modalState.close}
              />
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default OrderDeadlineDisplay;
